import { defineStore } from 'pinia';
import { useAxios } from '@/axios';
import { useGlobalComponentsStore } from '@/stores/global';

type DocumentModuleEnumValueEnums = {
    [key: string]: string;
}

type DocumentModuleEnums = {
    [key: string]: DocumentModuleEnumValueEnums;
}

export enum DocumentModuleModulesEnum {
    FORMS = 0,
    EMAILS = 1,
    ISO9001_DOCUMENTATION = 2,
    ISO9001_CERTIFICATION = 3,
    SCC_DOCUMENTATION = 4,
    SCC_CERTIFICATION = 5,
    ISO3834_DOCUMENTATION = 6,
    ISO3834_CERTIFICATION = 7,
    ISO9001_AUDIT = 8,
    SCC_AUDIT = 9,
    ISO3834_AUDIT = 10,
}

export enum DocumentFilesModuleTypesEnum {
    ISO9001_CERTIFICATE = 1,
    SCC_CERTIFICATE = 0,
    ISO3834_CERTIFICATE = 2,
}

export const useDocumentEnumsStore = defineStore({
    id: 'documentEnumsStore',
    state: () => ({
        data: {} as DocumentModuleEnums,
    }),
    getters: {
        getData: (state) => state.data,
        getSpecifiedData: (state) => (key: string) => key in state.data ? state.data[key] : {}
    },
    actions: {
        async fetch() {
            useGlobalComponentsStore().toggleShowLoading();
            const response = await useAxios().get(
                `api/v0/enumerators?with=quality_management_system_document_modules,quality_management_system_document_types`
            );
            this.data = response.data;
            useGlobalComponentsStore().toggleShowLoading();
        },
    },
});
